.verification-code {
  text-align: center;
  margin-top: 0px;
  margin-bottom: 12px;
}

.verification-resend-code {
  height: 19px;
  color: var(--darkgrayCredentials);
  font-family: "Open Sans";
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px;
  text-align: center;
  margin-bottom: 88.3px;
}

.verification-resend-code.link {
  color: var(--black);
  cursor: pointer;
}
